import { Column } from '@/components/Column';
import {
  AgoraLinkTypeE,
  AgoraThemeI,
  RoundedE,
  SubtitleTypeFontE,
  TypeButtonsE,
  VendorI,
  VendorLinkI
} from '@/types/cyclone/models';
import React, { FunctionComponent } from 'react';
import Featured from './Featured';
import Button from './Button';

type NavigationProps = {
  themeType: AgoraThemeI;
  username: string;
  links: VendorLinkI[];
  vendor: VendorI;
};

const Navigation: FunctionComponent<NavigationProps> = ({ themeType, username, links, vendor }) => {
  const { colors } = themeType;

  const getExternalLink = (link: string) => {
    const formattedExternalLink = link.replace(/^https?:\/\//, '');
    const externalLink = `https://${formattedExternalLink}`;
    return externalLink;
  };

  const renderLink = (link: VendorLinkI) => {
    const isFeaturedLink =
      link.agora_link_type === AgoraLinkTypeE.YOUTUBE || link.agora_link_type === AgoraLinkTypeE.MAPS;

    if (isFeaturedLink) {
      return (
        <Featured
          themeType={themeType}
          featured={{
            url: link.url || '',
            title: link.name,
            address: vendor.address,
            type: link.agora_link_type!,
            latitude: vendor.latitude,
            longitude: vendor.longitude
          }}
          type={vendor.vendor_storefront?.button_type || TypeButtonsE.SOLID}
          rounded={vendor.vendor_storefront?.border_radius_px || RoundedE.ROUNDED_0}
          fontTheme={vendor.vendor_storefront?.font_type || SubtitleTypeFontE.RALEWAY}
        />
      );
    } else {
      return (
        <a
          href={
            link.agora_link_type === AgoraLinkTypeE.SUBSCRIPTION
              ? `/${username}/planes`
              : link.is_agora_link
                ? `/${username}/${link.agora_link_type}`
                : getExternalLink(link.url || '')
          }
          target={link.is_agora_link ? '_self' : '_blank'}
          rel="noreferrer"
        >
          <Button
            colors={colors}
            text={link.name}
            type={vendor.vendor_storefront?.button_type || TypeButtonsE.SOLID}
            rounded={vendor.vendor_storefront?.border_radius_px || RoundedE.ROUNDED_0}
            fontTheme={vendor.vendor_storefront?.font_type || SubtitleTypeFontE.RALEWAY}
          />
        </a>
      );
    }
  };

  const orderedLinks = links.sort((a, b) => a.order - b.order);

  return (
    <Column className="max-w-[800px] w-[90%] m-auto mt-5">
      {orderedLinks.map((link: VendorLinkI, index) => (
        <div key={index}>{renderLink(link)}</div>
      ))}
    </Column>
  );
};

export default Navigation;
