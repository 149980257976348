import { trackGenericEvent } from '@/utils/analytics';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';

type MediaIconProps = {
  icon: IconDefinition;
  color: string;
  link: string;
  width?: number;
};

export const MediaIcon: FunctionComponent<MediaIconProps> = ({ icon, color, link, width }) => {
  const linkWithHTTPS = () => (link.includes('https://') ? link : `https://${link}`);

  const handleLinkClick = () => {
    trackGenericEvent('Footer Social Media Link Clicked', { social_media: icon });
  };

  return (
    <Link href={linkWithHTTPS()} onClick={handleLinkClick} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={icon} fontSize={width || 16} color={color} />
    </Link>
  );
};
