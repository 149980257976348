import { Column } from '@/components/Column';
import { AgoraThemeI, SizeImageE, SubtitleTypeFontE, TitleTypeFontE, VendorI } from '@/types/cyclone/models';
import { configFontStyle } from '@/utils';
import { commonImageProps } from '@/utils/images';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Image from 'next/legacy/image';
import React, { FunctionComponent } from 'react';

type HeaderProps = {
  themeType: AgoraThemeI;
  vendor: VendorI;
  cover_url?: string;
  professional_title?: string;
  main_title?: string;
};

const Header: FunctionComponent<HeaderProps> = ({
  themeType,
  vendor,
  cover_url,
  professional_title,
  main_title
}) => {
  const { colors } = themeType;
  const { vendor_storefront, user } = vendor;

  const isBannerDisplayed = vendor_storefront?.is_banner_displayed && vendor_storefront.cover_url !== null;

  const coverUrl = cover_url || vendor_storefront?.cover_url;
  const professionalTitle = professional_title || vendor.professional_title;
  const mainTitle = main_title || vendor_storefront?.name;

  return (
    <>
      {isBannerDisplayed && (
        <div
          className={classNames({
            'w-full relative z-10': true,
            'h-[180px] md:h-[412px]': vendor_storefront.size_banner !== SizeImageE.SMALL,
            'h-[134px] md:h-[358px]': vendor_storefront.size_banner === SizeImageE.SMALL
          })}
        >
          <Image
            {...commonImageProps(coverUrl!)}
            layout="fill"
            alt={`banner-${user.first_name}-${user.last_name}`}
            priority
          />
        </div>
      )}
      <div className={vendor.vendor_storefront?.title_font_type || TitleTypeFontE.RALEWAY}>
        <Column align="center" justify="center" className="w-full h-full">
          {vendor_storefront?.is_avatar_displayed && (
            <div
              className={classNames('relative rounded-[50%] overflow-hidden z-10', {
                'w-[168px] h-[168px] lg:w-[250px] lg:h-[250px]':
                  vendor_storefront.size_avatar === SizeImageE.BIG,
                'w-[120px] h-[120px] lg:w-[200px] lg:h-[200px]':
                  vendor_storefront.size_avatar === SizeImageE.SMALL,
                'mt-[-85px] lg:mt-[-120px]':
                  vendor_storefront.size_avatar === SizeImageE.BIG && isBannerDisplayed,
                'mt-[-60px] lg:mt-[-105px]':
                  vendor_storefront.size_avatar === SizeImageE.SMALL && isBannerDisplayed,
                'mt-[60px] lg:mt-[119px]': !isBannerDisplayed
              })}
              style={{
                borderColor: vendor_storefront?.pattern_type ? 'white' : colors.background,
                borderWidth: 5
              }}
            >
              {user.avatar_url ? (
                <Image
                  alt={`avatar-${user.first_name}-${user.last_name}`}
                  layout="responsive"
                  width={vendor_storefront.size_avatar === SizeImageE.BIG ? 250 : 200}
                  height={vendor_storefront.size_avatar === SizeImageE.BIG ? 250 : 200}
                  objectFit="cover"
                  objectPosition="center"
                  src={`${user.avatar_url}_250.webp`}
                  priority={true}
                  loading="eager"
                />
              ) : (
                <div className="bg-white rounded-[50%] w-full h-full flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faCircleUser}
                    color="#DADCE0"
                    style={{ width: '50%', height: '50%' }}
                  />
                </div>
              )}
            </div>
          )}
          <div
            className={classNames(
              'text-center mb-[10px] lg:mb-[20px] leading-tight font-medium max-w-[90%]',
              {
                '!mb-[0px] leading-tight text-[22px] lg:text-[60px]': main_title !== undefined,
                'mb-[10px] leading-tight text-[30px] lg:text-[60px]': main_title === undefined,
                'mt-[60px] lg:mt-[119px]':
                  !vendor_storefront?.is_avatar_displayed && !vendor_storefront?.is_banner_displayed,
                'mt-[10px] lg:mt-[20px]':
                  vendor_storefront?.is_avatar_displayed || vendor_storefront?.is_banner_displayed
              }
            )}
            style={{ color: colors.title }}
          >
            {mainTitle}
          </div>
          <div
            className={classNames(
              'text-center sm:max-w-[60%] max-w-[90%]',
              configFontStyle(vendor.vendor_storefront?.font_type)
            )}
            style={{ color: colors.title }}
          >
            <span className={vendor.vendor_storefront?.font_type || SubtitleTypeFontE.RALEWAY}>
              {professionalTitle}
            </span>
          </div>
        </Column>
      </div>
    </>
  );
};

export default Header;
