import { useScreenSize } from '@/hooks';
import { AgoraThemeColorI, RoundedE, TypeButtonsE, SubtitleTypeFontE } from '@/types/cyclone/models';
import { configFontStyle, normalizeText } from '@/utils';
import classNames from 'classnames';
import React, { FunctionComponent, useState } from 'react';

interface ButtonThemeI {
  colors: AgoraThemeColorI;
  text: string;
  type: TypeButtonsE;
  rounded: RoundedE;
  fontTheme: SubtitleTypeFontE;
}

const Button: FunctionComponent<ButtonThemeI> = ({ colors, text, type, rounded, fontTheme }) => {
  const [isHover, setIsHover] = useState(false);
  const { isSm } = useScreenSize();

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const getBorderRadius = (rounded: RoundedE) => {
    switch (rounded) {
      case RoundedE.ROUNDED_0:
        return rounded;

      case RoundedE.ROUNDED_10:
        return isSm ? rounded : 6;

      case RoundedE.ROUNDED_20:
      case RoundedE.ROUNDED_30:
        return isSm ? 30 : 25;

      default:
        return 0;
    }
  };

  const styles = {
    borderRadius: getBorderRadius(rounded),
    color: colors.text,
    ...(type === TypeButtonsE.SOLID && { background: colors.button }),
    ...(type === TypeButtonsE.OUTLINE && { border: `2px solid ${colors.button}` }),
    ...(type === TypeButtonsE.DARK_SHADOW && {
      border: `2px solid ${colors.button}`,
      boxShadow: `4px 4px 0px ${colors.shadow}`,
      background: colors.button
    }),
    ...(type === TypeButtonsE.SOFT_SHADOW && {
      boxShadow: `3px 3px 13px -6px ${colors.shadow}`,
      background: colors.button
    })
  };

  return (
    <button
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classNames(
        `p-2 sm:p-4 flex items-center justify-center w-full mb-5 transition-all duration-300 hover:scale-[1.01] min-h-[50px] sm:min-h-[60px]`
      )}
      style={{
        ...styles,
        transform: 'perspective(1px) translateZ(0)',
        scale: isSm && isHover ? '1.02' : '1'
      }}
    >
      <span className={`${configFontStyle(fontTheme)}`}>{normalizeText(text)}</span>
    </button>
  );
};

export default Button;
