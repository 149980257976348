import { Map } from '@/components/Map';
import { Row } from '@/components/Row';
import { useScreenSize } from '@/hooks';
import {
  AgoraLinkTypeE,
  AgoraThemeI,
  FeaturedI,
  RoundedE,
  SubtitleTypeFontE,
  TypeButtonsE
} from '@/types/cyclone/models';
import { configFontStyle } from '@/utils';
import { faChevronDown, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';

interface FeaturedProps {
  featured: FeaturedI;
  themeType: AgoraThemeI;
  type: TypeButtonsE;
  rounded: RoundedE;
  fontTheme: SubtitleTypeFontE;
}
const Featured: FunctionComponent<FeaturedProps> = ({ featured, themeType, type, rounded, fontTheme }) => {
  const { isSm } = useScreenSize();
  const heigth = isSm ? 360 : 250;
  const [isHidden, setIsHidden] = useState(false);
  const [maxHeight, setMaxHeight] = useState(isSm ? '60px' : '50px');
  const ref = useRef<HTMLDivElement>(null);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    if (isHidden) {
      setMaxHeight(ref.current ? `${ref.current.scrollHeight}px` : isSm ? '60px' : '50px');
    } else {
      setMaxHeight(isSm ? '60px' : '50px');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHidden, isSm]);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const getBorderRadius = (rounded: RoundedE) => {
    switch (rounded) {
      case RoundedE.ROUNDED_0:
        return rounded;

      case RoundedE.ROUNDED_10:
        return isSm ? rounded : 6;

      case RoundedE.ROUNDED_20:
      case RoundedE.ROUNDED_30:
        return isSm ? 30 : 25;

      default:
        return 0;
    }
  };

  const styles = {
    borderRadius: getBorderRadius(rounded),
    color: themeType.colors.text,
    ...(type === TypeButtonsE.SOLID && { background: themeType.colors.button }),
    ...(type === TypeButtonsE.OUTLINE && { border: `2px solid ${themeType.colors.button}` }),
    ...(type === TypeButtonsE.DARK_SHADOW && {
      border: `2px solid ${themeType.colors.button}`,
      boxShadow: `4px 4px 0px ${themeType.colors.shadow}`,
      background: themeType.colors.button
    }),
    ...(type === TypeButtonsE.SOFT_SHADOW && {
      boxShadow: `3px 3px 13px -6px ${themeType.colors.shadow}`,
      background: themeType.colors.button
    })
  };

  return (
    <div className="relative z-[1]">
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classNames(
          'max-w-[800px] w-full m-auto rounded-md overflow-hidden transition-all duration-500 relative mb-5'
        )}
        style={{
          height: maxHeight,
          ...styles,
          transform: 'perspective(1px) translateZ(0)',
          scale: isSm && isHover && !isHidden ? '1.02' : '1'
        }}
      >
        <div
          className={classNames('cursor-pointer px-2 relative h-full', { 'z-10': !isHidden })}
          onClick={() => setIsHidden(true)}
        >
          <Row justify="center" align="center" className="h-full">
            <Row align="center">
              {!isHidden && (
                <p
                  className={classNames(
                    ` text-center w-full ${configFontStyle(fontTheme)} text-[15px] md:text-[20px]`
                  )}
                >
                  {featured.title}
                </p>
              )}
            </Row>

            <FontAwesomeIcon
              icon={faChevronDown}
              color={themeType.colors.text}
              className="absolute right-0 mr-5"
            />
          </Row>
        </div>
        <div
          ref={ref}
          className={classNames('transition-all duration-600 absolute top-0 w-full', {
            'opacity-0': !isHidden,
            'opacity-1': isHidden
          })}
        >
          <div>
            {featured.type === AgoraLinkTypeE.YOUTUBE && isHidden ? (
              <ReactPlayer url={featured.url} controls width="100%" height={heigth} />
            ) : (
              <Map
                height={`${heigth}px`}
                address={featured.address!}
                latitude={featured.latitude!}
                longitude={featured.longitude!}
              />
            )}
          </div>
        </div>
      </div>
      {isHidden && (
        <Row
          className="bg-white/[.5] w-11 h-11 m-auto mt-5 border cursor-pointer rounded-[50%] mb-5"
          justify="center"
          align="center"
          onClick={() => setIsHidden(false)}
        >
          <FontAwesomeIcon icon={faClose} width={12} />
        </Row>
      )}
    </div>
  );
};

export default Featured;
