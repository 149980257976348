import { MediaIcon } from '@/components/MediaIcon';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

export const renderMediaIcon = (
  id: number,
  icon: IconDefinition,
  link: string,
  color: string,
  width?: number
): React.ReactNode => <MediaIcon key={id} icon={icon} color={color} link={link} width={width} />;
