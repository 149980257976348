import { renderMediaIcon } from '@/components/MediaIcon';
import { Row } from '@/components/Row';
import { AgoraThemeI, VendorSocialAppI } from '@/types/cyclone/models';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faMedium,
  faPinterest,
  faReddit,
  faSnapchat,
  faSpotify,
  faTiktok,
  faTwitch,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import React, { FunctionComponent } from 'react';

type SocialProps = {
  themeType: AgoraThemeI;
  socialMedias: VendorSocialAppI[];
};

const Social: FunctionComponent<SocialProps> = ({ themeType, socialMedias }) => {
  const { colors } = themeType;

  const getSocialIcon = (socialApp: string) => {
    switch (socialApp) {
      case 'facebook':
        return faFacebook;
      case 'instagram':
        return faInstagram;
      case 'twitter':
        return faTwitter;
      case 'linked_in':
        return faLinkedin;
      case 'youtube':
        return faYoutube;
      case 'pinterest':
        return faPinterest;
      case 'tiktok':
        return faTiktok;
      case 'snapchat':
        return faSnapchat;
      case 'twitch':
        return faTwitch;
      case 'medium':
        return faMedium;
      case 'spotify':
        return faSpotify;
      case 'reddit':
        return faReddit;
      default:
        return faLink;
    }
  };

  const getSocialMediaJSX = () => {
    const filteredSocialMedias = socialMedias.filter(
      (socialMedia) => socialMedia.social_app?.name !== 'whatsapp'
    );
    const orderedSocialMedias = filteredSocialMedias.sort((a, b) => a.order - b.order);
    return orderedSocialMedias.map((socialMedia: VendorSocialAppI) => {
      const { link, social_app } = socialMedia;
      return renderMediaIcon(socialMedia.id, getSocialIcon(social_app!.name), link, colors.title, 25);
    });
  };

  return (
    <div className="mt-5">
      <Row gap={16} align="center" justify="center" className="flex-1 flex-wrap">
        {getSocialMediaJSX()}
      </Row>
    </div>
  );
};

export default Social;
